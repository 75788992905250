<template>
  <div class="order-item" style="display: flex">
    <div class="order-item__left">
      <div class="order-item__left-header">
        <div class="title">
          <span class="title-tag">
            {{ $t("BOOKINGS.BOOKING_IDENTIFIER", { code: order.code }) }}
          </span>
          <el-tag type="warning">
            {{ $t(`SALES_ORDERS.ORDER_STATUS_${order.status}`) }}
          </el-tag>
        </div>
        <div class="info">
          <ul>
            <li>{{ $t("COMMON.CUSTOMER") }} :</li>
            <li>
              <span v-if="order.recipient">
                {{ order.recipient.firstname }}
                {{ order.recipient.lastname }}
              </span>
            </li>
          </ul>
          <ul>
            <li>{{ $t("SALES_ORDERS.RECEPTIONIST_IN_CHARGE") }} :</li>
            <li>
              <span>{{ receptionistInCharge }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <booking-card
        v-for="(item,i) in order.items"
        :key="i"
        :orderItem="item"
        :booking="item.salesOrderable"
        @onEditBooking="() => editBooking(order)"
      />
    </div>
    <div class="order-item__right">
      <el-button
        v-if="isOwner && order.recipient"
        type="primary"
        @click="() => confirmOrder(order)"
      >
        {{ $t("BOOKINGS.CONFORM_PAYMENT") }}
      </el-button>
      <el-button
        v-if="!isOwner || !order.recipient"
        type="primary"
        @click="() => editBooking(order)"
      >
        {{ $t("COMMON.COMPLETE") }}
      </el-button>
      <el-button
        v-if="isOwner"
        type="secondary"
        @click="() => newBooking(order)"
      >
        {{ $t("BOOKINGS.ADD_BOOKING") }}
      </el-button>
      <el-button type="secondary" @click="() => deleteOrder(order)">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
      <div class="summary">
        <order-item-summary
          v-for="item in order.items"
          :key="item.id"
          :item="item"
        />
        <div class="section total">
          <dl class="row">
            <dt>
              <b>{{ $t("COMMON.TOTAL") }}</b>
            </dt>
            <dd>{{ order.pricing.total }} CAD</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tag, Button } from "element-ui";
import BookingCard from "./BookingCard.vue";
import OrderItemSummary from "./OrderItemSummary.vue";

export default {
  name: "order-card",
  components: {
    BookingCard,
    OrderItemSummary,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },
  props: {
    order: {
      type: Object,
      default: null,
      description: "SalesOrder object",
    },
    isOwner: {
      type: Boolean,
      default: false,
      description: "is owner of the order",
    },
  },
  data() {
    return {};
  },
  methods: {
    confirmOrder(order) {
      this.$emit("onConfirmOrder", order);
    },
    deleteOrder(order) {
      this.$emit("onDeleteOrder", order);
    },
    editBooking(order) {
      this.$emit("onEditBooking", order);
    },
    newBooking(order) {
      this.$emit("onNewBooking", order.recipient);
    },
  },
  
  computed: {
    /**
     * Renvoie le Prénom + Nom du réceptioniste en charge de la réservation.
     * 
     * Soit il s'agit de celui qui l'a mise à jour soit de celui qui l'a créée.
     */
    receptionistInCharge(){
      if(this.order.updatedBy) {
        return `${this.order.updatedBy.firstname} ${this.order.updatedBy.lastname}` ;
      }
      return `${this.order.createdBy.firstname} ${this.order.createdBy.lastname}` ;
    }
  }
};
</script>
