<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!order">
      <span class="loader"></span>
    </span>

    <div class="booking-confirmation" v-else>
      <div class="booking-confirmation-left">
        <div class="booking-confirmation-left-title">
          <h1>{{ $t("BOOKINGS.CONFIRM_THE_BOOKING") }}</h1>
          <small>référence #{{ order.code }}</small>
        </div>
        <div class="booking-confirmation-left-tabs">
          <el-tabs v-model="activeTab">
            <el-tab-pane
              :label="$t('BOOKINGS.BOOK_WITH_PAYMENT')"
              :name="WITH_PAYMENT"
            >
              <booking-confirmation-with-payment
                v-if="order"
                :orderData="order"
                :key="renderKey * 100"
                @onSubmitted="handleSubmit"
              />
            </el-tab-pane>
            <el-tab-pane
              :label="$t('BOOKINGS.BOOK_WITHOUT_PAYMENT')"
              :name="WITHOUT_PAYMENT"
            >
              <booking-confirmation-without-payment
                v-if="order"
                :orderData="order"
                :key="renderKey * 200"
                @onSubmitted="handleSubmit"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="booking-confirmation-right">
        <div class="summary">
          <h2>Résumé final de la réservation</h2>
          <div v-for="item in order.items" :key="item.id">
            <div class="section date">
              <dl class="row">
                <dt>Date de réservation</dt>
                <dd>
                  {{ item.quantity }} nuits:
                  {{ $formatDate(item.salesOrderable.start_at, "D MMM") }} au
                  {{ $formatDate(item.salesOrderable.end_at, "Do MMM") }}
                </dd>
              </dl>
            </div>
            <div class="section">
              <!-- <h4>{{ item.salesOrderable.spot.name }}</h4> -->
              <dl class="row">
                <dt>
                  {{ item.salesOrderable.spot.name }}
                </dt>
                <dd>{{ item.unit_price }} CAD</dd>
              </dl>
              <dl class="row">
                <dt>{{ $t("COMMON.SUBTOTAL") }}</dt>
                <dd>{{ item.pricing.subtotal }} CAD</dd>
              </dl>
            </div>
          </div>
          <div class="section tps">
            <dl class="row">
              <dt>TPS</dt>
              <dd>5.08 CAD</dd>
            </dl>
            <dl class="row">
              <dt>TVQ</dt>
              <dd>12,14 CAD</dd>
            </dl>
          </div>
          <div class="section total">
            <dl class="row">
              <dt>
                <b>Total</b>
                <!-- <small>inclus 2.24 CAD de taxes</small> -->
              </dt>
              <dd>{{ total }} CAD</dd>
            </dl>
            <!-- <dl class="row">
              <dt class="col-6">TVQ</dt>
              <dd>12,14 CAD</dd>
            </dl> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import BookingConfirmationWithoutPayment from "../partials/form/BookingConfirmationWithoutPayment";
import BookingConfirmationWithPayment from "../partials/form/BookingConfirmationWithPayment";
import { Col, Row, Tabs, TabPane } from "element-ui";
import swal from "sweetalert2";

export default {
  layout: "DashboardLayout",

  components: {
    BookingConfirmationWithoutPayment,
    BookingConfirmationWithPayment,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },

  mixins: [alertLeave],

  props: {
    salesOrderId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    const WITH_PAYMENT = "with_payment";
    const WITHOUT_PAYMENT = "without_payment";
    return {
      order: null,
      activeTab: WITH_PAYMENT,
      formErrors: null,
      loading: false,
      WITH_PAYMENT,
      WITHOUT_PAYMENT,
      renderKey: 1,
    };
  },

  computed: {
    total: function () {
      return this.order.items.reduce(
        (acc, item) => item.pricing.subtotal + acc,
        0
      );
    },
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("salesOrders/get", this.salesOrderId);
        this.order = this.$store.getters["salesOrders/salesOrder"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(order) {
      this.loading = true;
      await swal.showLoading();
      try {
        const orderData = cloneDeep(order);

        console.log({ orderData });

        await this.$store.dispatch("salesOrders/confirmBooking", {
          id: orderData.id,
          data: orderData,
        });

        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_CONFIRMED"),
        });

        this.$emit("onCloseBookingModal", false);

        this.loading = false;
        swal.close();
      } catch (error) {
        swal.close();
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
